import React from 'react'
import { array, string, func, object } from 'prop-types'

import { NativeAuthConsumer } from 'contexts/NativeAuth'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import OrderForm from 'components/BoostPlan/OrderForm'
import { H2 } from 'styles/typography'

const Order = ({ products, step, setStep, selectedProductHandle, selectedVariantId, submitOrder }) => {
  const selectedProduct = products.find(product => product.node.handle === selectedProductHandle).node

  return (
    <Section>
      <Wrapper narrow>
        <SectionBlock className="u-text-center">
          <H2>Review your order</H2>
        </SectionBlock>
        <SectionBlock>
          <NativeAuthConsumer>
            {({ user }) => {
              return (
                <OrderForm
                  selectedProduct={selectedProduct}
                  selectedVariantId={selectedVariantId}
                  user={user}
                  submitOrder={submitOrder}
                />
              )
            }}
          </NativeAuthConsumer>
        </SectionBlock>
      </Wrapper>
    </Section>
  )
}

Order.propTypes = {
  products: array,
  selectedProductHandle: string,
  selectedVariantId: string,
  step: object,
  setStep: func,
  submitOrder: func
}

export default Order
