import React, { useEffect, useState } from 'react'
import { array, string, func, object } from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { stripUnit, rgba } from 'polished'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { BOOST_PLAN_STEPS } from 'pages'
import { getEpodCartridges } from 'lib/graphql/queries'

import { Query } from 'react-apollo'
import { NativeAuthConsumer } from 'contexts/NativeAuth'
import Wrapper from 'components/Wrapper'
import Section from 'components/Section'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import Button from 'components/button/Button'
import Modal, { ModalSection } from 'components/Modal/index'
import Icon from 'components/Icon'
import { H1, H2, H3, H4, fontSizes, fontWeights, lineHeights } from 'styles/typography'
import { white, green, black } from 'styles/colors'
import { navHeight, navHeightMobile, square } from 'styles/global'
import { media, mediaDown } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  background: linear-gradient(180deg, #000000 0%, #131922 100%);
  margin-top: -${navHeightMobile};
  padding-top: ${navHeightMobile};

  ${media.medium`
    background: linear-gradient(40.51deg, #000000 24.15%, #131922 70.1%);
    margin-top: -${navHeight};
    padding-top: ${navHeight};
  `};
`

const Hero = styled.div`
  position: relative;
  padding: ${spacing.xLarge} 0;

  * {
    color: ${white} !important;
  }

  ${media.small`
    padding: ${spacing.xxLarge} 0;
  `};

  ${mediaDown.medium`
    text-align: center;
  `};

  ${media.medium`
    padding: ${spacing.xxxLarge} 0;
  `};

  ${media.large`
    padding: 56px 0;
  `};

  ${media.xLarge`
    padding: 64px 0;
  `};
`

const Inner = styled.div`
  position: relative;
  z-index: 10;

  ${media.medium`
    display: flex;
    flex-direction: column;
  `};
`

const Main = styled.div`
  position: static;

  ${media.medium`
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 30%;
    padding-top: 5%;
  `};
`

const Content = styled.div`
  max-width: 380px;
  margin: 0 auto;

  ${media.small`
    max-width: 430px;
  `};

  ${media.medium`
    max-width: 500px;
  `};
`

const Heading = styled(H1)`
  line-height: ${lineHeights.heading};
  font-weight: ${fontWeights.bold};
  margin-bottom: 0;
`

const Subheading = styled(H2)`
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.base};
  margin-bottom: ${spacing.large};

  ${media.medium`
    margin-bottom: ${spacing.xLarge};
    padding-right: 5%;
  `};

  ${media.large`
    margin-bottom: ${spacing.xxLarge};
  `};

  ${media.xLarge`
    margin-bottom: ${spacing.xxxLarge};
  `};
`

const FigureWrap = styled.div`
  margin-left: auto;
  margin-right: auto;

  ${media.medium`
    position: absolute;
    top: 0;
    right: 76%;
    margin-top: -3vw;
    margin-bottom: 0;
    width: 22vw
    max-width: 300px;
    transform: translateX(20%);
  `};

  ${media.large`
    transform: translateX(30%);
  `};

  ${media.xLarge`
    transform: translateX(35%);
  `};
`

const Figure = styled.img`
  width: 120px;
  margin-bottom: ${spacing.medium};

  ${media.small`
    width: 160px;
  `};

  ${media.medium`
    width: 100%;
  `};
`

const Subtitle = styled(H4)`
  color: ${white};
  text-transform: uppercase;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const Arrow = styled(AnchorLink)`
  color: ${white};
  display: block;
  width: 120px;
  text-align: center;
  margin-top: ${spacing.large};
  margin-left: auto;
  margin-right: auto;

  ${media.small`
    margin-top: ${spacing.xLarge};
  `};

  ${media.medium`
    margin-top: ${spacing.xxLarge};
  `};

  ${media.large`
    margin-top: ${spacing.xxxLarge};
  `};

  ${media.xLarge`
    margin-top: 56px;
  `};
`

const ArrowIcon = styled(Icon)`
  ${square('32px !important')};

  ${media.medium`
    ${square('40px !important')};
  `};
`

const Feature = styled.div`
  position: relative;
  display: flex;
  color: ${rgba(white, 0.8)};
  padding-left: 44px;
  padding-right: ${spacing.small};

  ${media.medium`
    padding-left: 52px;
  `};
`

const FeatureIconWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${black};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  border-radius: 50%;
  ${square('28px')};
`

const RequirementsDisclaimer = styled.p`
  font-size: ${fontSizes.tiny};
  margin-bottom: ${spacing.xLarge};

  ${mediaDown.medium`
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.medium`
    margin-bottom: ${spacing.xxLarge};
  `}

  ${media.large`
    margin-bottom: ${spacing.xxxLarge};
  `};

  ${media.xLarge`
    margin-bottom: 56px;
  `};
`

const RequirementsButton = styled.button`
  text-decoration: underline;
  font-size: ${fontSizes.tiny};
`

const Requirement = styled.div`
  position: relative;
  display: flex;

  & + & {
    margin-top: ${spacing.large};
  }
`

const FieldIcon = styled(Icon)`
  fill: ${green};
  flex-shrink: 0;
  margin-right: ${spacing.medium};
  margin-top: -2px;
  ${square`32px`};

  ${media.medium`
    margin-right: ${spacing.large};
  `}

  ${media.large`
    margin-right: ${spacing.xLarge};
  `}
`

const Cartridges = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing.small};

  ${media.medium`
    margin-top: 0;
  `}
`

const CartridgeItem = styled.div`
  padding: ${spacing.small};
`

const Cartridge = styled.div`
  text-align: center;
  color: ${white};
  font-size: 11px;
  text-transform: uppercase;
  width: 80px;

  img {
    width: 48px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${spacing.small};
    transform: rotate(-90deg);

    ${media.medium`
      margin-bottom: ${spacing.medium};
    `}
  }
`

const Strengths = styled.div`
  display: flex;
  align-items: center;

  ${media.medium`
    margin-top: ${spacing.medium};
  `}
`

const Strength = styled(H1)`
  color: ${white};
  font-weight: ${fontWeights.medium};
  margin: 0;

  sub {
    bottom: 0;
    font-size: 55%;
  }

  & + & {
    padding-left: ${spacing.large};
    margin-left: ${spacing.large};
    border-left: 1px solid ${rgba(white, 0.25)};
  }
`

const StrengthLogo = styled.img`
  width: 48px;
  margin-left: ${spacing.large};

  ${media.medium`
    width: 56px;
  `}

  ${media.large`
    width: 64px;
  `}
`

const StrengthsLabel = styled.small`
  display: block;
  margin-top: ${spacing.small};

  ${media.medium`
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `}
`

const Disclaimer = styled(StrengthsLabel)`
  font-size: ${fontSizes.tiny};
  color: ${rgba(white, 0.75)};
  text-align: center;
`

const Landing = ({ products, step, setStep, user, signupError, selectProduct, history }) => {
  const [requirementsModalVisibility, setRequirementsModalVisibility] = useState(false)

  useEffect(() => {
    if (user) {
      if (products.length > 1 || products[0].node.handle === 'epen-3') {
        setStep(BOOST_PLAN_STEPS.productSelection)
      } else {
        let product = products[0].node

        if (products[0].node.handle === 'vype-epod-vape') {
          selectProduct(product.handle, product.variants.edges.find(variant => variant.node.sku === '10081570').node.id)
        } else {
          selectProduct(product.handle, product.variants.edges[0].node.id)
        }
      }
    }
  }, [user])

  useEffect(() => {
    if (signupError === 'emailAlreadyExists') history.push('not-eligible')
  }, [signupError])

  const inactiveCampaign = products === null
  const combo = inactiveCampaign || products.length > 1

  return (
    <NativeAuthConsumer>
      {({ signUpModalVisibility, setSignupModalVisibility, signup, province }) => (
        <>
          <Container>
            <Hero>
              <Wrapper>
                <Inner>
                  <FigureWrap>
                    <Figure src={require(`static/images/hero-device.png`)} alt="Vype ePod" />
                  </FigureWrap>
                  <Main>
                    <Content>
                      <Heading>Make your move</Heading>
                      <Subheading>Switch to Vype</Subheading>
                      <H4>Don't miss this offer</H4>
                      <p>
                        {`Sleek, stylish and quiet. ${
                          combo ? `A pocket sized vaping product` : `The pocket sized ${products[0].node.title}`
                        } is designed to give you a smooth and satisfying taste experience.`}
                      </p>
                      <H4>{`Register below and in exchange receive a ${
                        combo ? `vaping product` : `${products[0].node.title}`
                      }`}</H4>
                      <RequirementsDisclaimer>
                        Vype products are not medically licensed smoking cessation devices.{` `}
                        <RequirementsButton
                          onClick={() => {
                            setRequirementsModalVisibility(true)
                          }}
                        >
                          See eligibility requirements
                        </RequirementsButton>
                      </RequirementsDisclaimer>
                      <Button
                        type="button"
                        theme="primary"
                        onClick={() => {
                          setSignupModalVisibility(true)
                        }}
                      >
                        Register now
                      </Button>
                      <Arrow offset={0} href="#features" className="u-hide-medium-up">
                        <ArrowIcon icon="down-chevron-large" />
                      </Arrow>
                    </Content>
                  </Main>
                  <Arrow offset={0} href="#features" className="u-hide-medium-down">
                    <ArrowIcon icon="down-chevron-large" />
                  </Arrow>
                </Inner>
              </Wrapper>
            </Hero>

            <Section id="features">
              <Wrapper narrow>
                <GridRow>
                  <GridItem xs="1-1" md="1-2">
                    <Feature>
                      <FeatureIconWrap>
                        <Icon icon="speaker" />
                      </FeatureIconWrap>
                      <div>
                        <Subtitle>Quiet & Smooth</Subtitle>
                        <p>{`The ${
                          combo ? `vaping product` : `${products[0].node.title}`
                        } is designed to deliver a quiet, smooth and satisfying taste experience.`}</p>
                      </div>
                    </Feature>
                  </GridItem>

                  <GridItem xs="1-1" md="1-2">
                    <Feature>
                      <FeatureIconWrap>
                        <Icon icon="bolt" />
                      </FeatureIconWrap>
                      <div>
                        <Subtitle>Hits Big</Subtitle>
                        <p>vPro nicotine salts cartridges deliver rich flavour satisfaction.</p>
                      </div>
                    </Feature>
                  </GridItem>

                  <GridItem xs="1-1" md="1-2">
                    <Feature>
                      <FeatureIconWrap>
                        <Icon icon="clock" />
                      </FeatureIconWrap>
                      <div>
                        <Subtitle>All-day use</Subtitle>
                        <p>{`Fully charged, ${
                          combo ? `vaping product` : `${products[0].node.title}`
                        } will deliver approximately 190 puffs, based on typical usage.`}</p>
                      </div>
                    </Feature>
                  </GridItem>

                  <GridItem xs="1-1" md="1-2">
                    <Feature>
                      <FeatureIconWrap>
                        <Icon icon="resize" />
                      </FeatureIconWrap>
                      <div>
                        <Subtitle>Perfectly pocket-sized</Subtitle>
                        <p>Compact design to fit in your pocket.</p>
                      </div>
                    </Feature>
                  </GridItem>
                </GridRow>
              </Wrapper>
            </Section>

            <Section flushTop>
              <Wrapper narrow>
                <GridRow>
                  <GridItem xs="1-1" md="1-2">
                    <Feature>
                      <div>
                        <Subtitle>3 Nicotine strengths</Subtitle>
                        <Strengths>
                          <Strength>
                            1.6<sub>%</sub>
                          </Strength>
                          <Strength>
                            3<sub>%</sub>
                          </Strength>
                          <Strength>
                            5<sub>%</sub>
                          </Strength>
                          <StrengthLogo src={require(`static/images/vpro-logo.png`)} alt="vPro logo" />
                        </Strengths>
                        <StrengthsLabel>Nicotine by weight</StrengthsLabel>
                      </div>
                    </Feature>
                  </GridItem>

                  <GridItem xs="1-1" md="1-2">
                    <Feature>
                      <div>
                        <Subtitle>6 intense Flavours</Subtitle>
                        <Query query={getEpodCartridges}>
                          {({ data, error, loading }) => {
                            if (error || loading) return null

                            return (
                              <>
                                <Cartridges>
                                  {data.collectionByHandle.products.edges.map(product => {
                                    const title = product.node.title.split(' ')

                                    let customTitle = 'Berry'
                                    if (title.includes('Berry')) customTitle = 'Berry Blast'
                                    if (title.includes('Strawberry')) customTitle = 'Garden Strawberry'
                                    if (title.includes('Mango')) customTitle = 'Mango'
                                    if (title.includes('Mint')) customTitle = 'Mint'
                                    if (title.includes('Tobacco')) customTitle = 'Tobacco'
                                    if (title.includes('Vanilla')) customTitle = 'Vanilla'

                                    return (
                                      <CartridgeItem>
                                        <Cartridge>
                                          <img src={product.node.images.edges[0].node.transformedSrc} />
                                          <span>{customTitle}</span>
                                        </Cartridge>
                                      </CartridgeItem>
                                    )
                                  })}
                                </Cartridges>
                                <StrengthsLabel>Simulated flavours</StrengthsLabel>
                              </>
                            )
                          }}
                        </Query>
                      </div>
                    </Feature>
                  </GridItem>
                </GridRow>
              </Wrapper>
            </Section>

            <Section className="u-text-center" flushTop flushBottom>
              <Wrapper>
                <Button
                  type="button"
                  theme="primary"
                  onClick={() => {
                    setSignupModalVisibility(true)
                  }}
                >
                  Register now
                </Button>
              </Wrapper>
            </Section>

            <Section>
              <Wrapper>
                <Disclaimer>
                  Vype products may be harmful to health and contain nicotine which is addictive. VYPE PRODUCTS ARE NOT
                  SUITABLE FOR USE BY: persons who are not adults; persons who are under the legal age to purchase
                  vaping products; persons who are allergic/sensitive to nicotine; pregnant or breast-feeding women;
                  persons who have been advised to avoid using tobacco or nicotine products for medical reasons; persons
                  with reduced physical, sensory, mental capabilities or lack of experience/knowledge unless they are
                  under supervision or have been given instructions concerning the use of the product by a person
                  responsible for their safety; and persons with an unstable heart condition, severe hypertension or
                  diabetes. Keep Vype products out of reach of children.
                </Disclaimer>
                <Disclaimer>
                  © Imperial Tobacco Company Ltd., 3711 Saint-Antoine Street West, Montreal, QC H4C 3P6
                </Disclaimer>
              </Wrapper>
            </Section>
          </Container>

          <Modal
            visible={requirementsModalVisibility}
            close={() => {
              setRequirementsModalVisibility(false)
            }}
          >
            <ModalSection>
              <H3>Eligibility requirements</H3>
            </ModalSection>
            <ModalSection>
              <Requirement>
                <FieldIcon icon="checkmark-small" />
                <p>Must be over the legal age to purchase vaping products in your province of residence</p>
              </Requirement>
              <Requirement>
                <FieldIcon icon="checkmark-small" />
                <p>Must NOT have an existing account on GoVype.ca</p>
              </Requirement>
              <Requirement>
                <FieldIcon icon="checkmark-small" />
                <p>Not eligible to residents in the provinces of Quebec, Nova Scotia and the Territories</p>
              </Requirement>
            </ModalSection>
          </Modal>
        </>
      )}
    </NativeAuthConsumer>
  )
}

Landing.propTypes = {
  user: object,
  signupError: string,
  products: array,
  step: object,
  setStep: func,
  selectProduct: func,
  history: object
}

export default withRouter(Landing)
