import React from 'react'
import { node, bool } from 'prop-types'

import { GlobalStyle } from 'styles/global'
import DefaultHelmet from 'components/DefaultHelmet'
import Header from 'components/Header'

const Layout = ({ contrast, children }) => {
  return (
    <>
      <GlobalStyle />
      <DefaultHelmet />
      <Header contrast={contrast} />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: node.isRequired,
  contrast: bool
}

export default Layout
