import React from 'react'
import styled from 'styled-components'
import { string, object, bool, node } from 'prop-types'
import { rgba, stripUnit } from 'polished'

import { white, primaryColor, grey, borderColor, red, greyLight, offWhite } from 'styles/colors'
import { animationTime, animationCurve, borderRadius, inputHeight } from 'styles/global'
import { fontSizes } from 'styles/typography'
import Icon from 'components/Icon'
import Label from 'components/Label'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Wrap = styled.div`
  position: relative;

  ${props =>
    props.narrow &&
    `
    max-width: 80px;
  `};
`

const Field = styled.input`
  height: ${inputHeight};
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${borderColor};
  background-color: ${white};
  width: 100%;
  resize: none;
  color: ${grey};
  vertical-align: top;
  outline: 0;
  padding: 0 ${spacing.medium};
  transition: all ${animationTime} ${animationCurve};

  ${props =>
    props.icon &&
    `
    padding-left: 46px;
  `} ${props =>
  props.addon &&
  `
    padding-right: ${spacing.xxxLarge};
  `} ${props =>
  props.error &&
  `
    box-shadow: inset 0 0 0 1px ${red}, 0 0 0 1px ${red};
  `}

  &::placeholder {
    color: ${rgba(grey, 0.4)}
  }

  &:focus {
    background-color: ${white};
    box-shadow: inset 0 0 0 1px ${primaryColor}, 0 0 0 1px ${primaryColor};
  }

  &[disabled] {
    color: ${greyLight};
    pointer-events: none;
    background-color: ${rgba(offWhite, 0.75)};
    user-select: none;
    opacity: 1; /* Overwrite iOS styles */
  }
`

const Textarea = styled(Field)`
  padding: ${stripUnit(spacing.medium) - 2 + 'px'} ${spacing.medium};
  min-height: 160px;
  resize: vertical;

  ${media.small`
    min-height: 200px;
  `};

  ${media.medium`
    min-height: 240px;
  `};

  ${media.large`
    min-height: 260px;
  `};
`

const FieldIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${stripUnit(spacing.medium) * 0.75 + 'px'};
  pointer-events: none;
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve};

  ${Field}:focus + & {
    color: ${primaryColor};
  }
`

const Addon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${spacing.medium};
  color: ${primaryColor};
  font-size: ${fontSizes.small};
`

export const ErrorMessage = styled.small`
  color: ${red};
  display: block;
  margin-top: ${spacing.small};
  font-size: ${fontSizes.tiny};
`

const Hint = styled.small`
  color: ${greyLight};
  display: block;
  margin-top: ${spacing.small};
  font-size: ${fontSizes.tiny};
`

const Input = ({ label, hint, narrow, input, addon, icon, textarea, meta = {}, ...rest }) => (
  <Container>
    {label && <Label>{label}</Label>}
    <Wrap narrow={narrow}>
      {!textarea && <Field icon={icon} addon={addon} error={meta.error && meta.touched} {...input} {...rest} />}
      {textarea && <Textarea as="textarea" error={meta.error && meta.touched} {...input} {...rest} />}
      {icon && <FieldIcon icon={icon} />}
      {addon && <Addon>{addon}</Addon>}
    </Wrap>
    {hint && <Hint>{hint}</Hint>}
    {meta && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
  </Container>
)

Input.propTypes = {
  label: string,
  icon: string,
  hint: string,
  narrow: bool,
  textarea: bool,
  input: object,
  meta: object,
  addon: node
}

export default Input
