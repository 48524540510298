import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import { stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

export const wrapperWidth = '1300px'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${wrapperWidth};
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${props =>
    props.narrow &&
    css`
      max-width: 960px;
    `}

  ${media.small`
    padding-left: ${spacing.xxLarge};
    padding-right: ${spacing.xxLarge};
  `};

  ${media.medium`
    padding-left: ${stripUnit(spacing.xLarge) * 1.5 + 'px'};
    padding-right: ${stripUnit(spacing.xLarge) * 1.5 + 'px'};
  `};
`

const Wrapper = ({ children, narrow }) => <Container narrow={narrow}>{children}</Container>

Wrapper.propTypes = {
  children: node.isRequired,
  narrow: bool
}

export default Wrapper
