import React from 'react'
import styled from 'styled-components'

import { white } from 'styles/colors'
import Spinner from 'components/Spinner'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  z-index: 450;
`

const Loading = () => (
  <Container>
    <Spinner />
  </Container>
)

export default Loading
