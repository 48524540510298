import React from 'react'
import { string, object } from 'prop-types'
import NumberFormat from 'react-number-format'

import Input from 'components/Input'

const NumberInput = ({ numberFormat, input, ...props }) => (
  <NumberFormat
    customInput={Input}
    format={numberFormat}
    {...input}
    {...props} />
)

NumberInput.propTypes = {
  numberFormat: string,
  input: object
}

export default NumberInput
