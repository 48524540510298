import React, { useState, useEffect } from 'react'
import { object, func } from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'qs'

import { client } from 'lib/graphql/client'
import { getProducts } from 'lib/graphql/queries'
import { PRODUCT_HANDLES } from 'utils/constants'

import Layout from 'layouts/Layout'
import { NativeAuthConsumer } from 'contexts/NativeAuth'
import Loading from 'components/Loading'
import Landing from 'components/BoostPlan/Landing'
import Order from 'components/BoostPlan/Order'
import ProductSelection from 'components/BoostPlan/ProductSelection'
import Final from 'components/BoostPlan/Final'
import SignUpModal from 'components/Modal/SignUpModal'
import Modal, { ModalSection } from 'components/Modal/index'
import { H3 } from 'styles/typography'
import SoftVerify from 'components/SoftVerify'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'

export const LANDING_PRODUCTS = {
  epen: 'ePen',
  epod: 'ePod',
  both: 'both'
}

export const BOOST_PLAN_STEPS = {
  landing: {
    component: Landing,
    name: 'landing'
  },
  order: {
    component: Order,
    name: 'order'
  },
  productSelection: {
    component: ProductSelection,
    name: 'productSelection'
  },
  final: {
    component: Final,
    name: 'final'
  }
}

const BoostPlan = ({ history, createBoostPlanOrder }) => {
  const [loading, setLoading] = useState(true)
  const [step, setStep] = useState(BOOST_PLAN_STEPS.landing)
  const [products, setProducts] = useState(null)
  const [selectedProductHandle, setSelectedProductHandle] = useState(null)
  const [selectedVariantId, setSelectedVariantId] = useState(null)
  const [inactiveCampaign, setInactiveCampaign] = useState(false)

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    const { data } = await client.query({ query: getProducts })
    const products = data.products.edges.filter(product => PRODUCT_HANDLES.includes(product.node.handle))

    // both
    // setProducts(products)

    setProducts([products.find(product => product.node.title === 'Vype ePod')])
    // setProducts([products.find(product => product.node.title === 'Vype ePen 3')])

    setInactiveCampaign(false)
    setLoading(false)
  }

  const selectProduct = (productHandle, variantId) => {
    setSelectedVariantId(variantId)
    setSelectedProductHandle(productHandle)
    setStep(BOOST_PLAN_STEPS.order)
  }

  const submitOrder = async (order, user) => {
    const decodedId = atob(selectedVariantId).split('/')

    const validOrder = {
      ...order,
      line_items: [
        {
          variant_id: decodedId[decodedId.length - 1],
          quantity: 1
        }
      ]
    }

    const userAddress = {
      address: validOrder.shipping_address.address1,
      subBuilding: validOrder.shipping_address.address2,
      city: validOrder.shipping_address.city,
      country: validOrder.shipping_address.country,
      province: validOrder.shipping_address.province_code,
      postalCode: validOrder.shipping_address.zip,
      geocodedAddress: validOrder.shipping_address.geocodedAddress
    }

    const payload = {
      user: _.omit({ ...user, ...userAddress }, ['addressLabel', 'street']),
      order: validOrder,
      storeName: 'vype'
    }

    try {
      await createBoostPlanOrder(payload)
      setStep(BOOST_PLAN_STEPS.final)
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const Step = step ? BOOST_PLAN_STEPS[step.name].component : BOOST_PLAN_STEPS[BOOST_PLAN_STEPS.landing.name].component

  if (loading) return <Loading />

  const urlParams = queryString.parse(history.location.search)
  const utmCampaign = urlParams['?utm_campaign'] || urlParams.utm_campaign || ''
  const utmSource = urlParams['?utm_source'] || urlParams.utm_source || ''
  const utmMedium = urlParams['?utm_medium'] || urlParams.utm_medium || ''

  return (
    <Layout contrast={step === BOOST_PLAN_STEPS.landing}>
      <NativeAuthConsumer>
        {({
          user,
          signupError,
          signUpModalVisibility,
          setSignupModalVisibility,
          signup,
          softVerifiedUser,
          setNativeSoftVerifiedUser
        }) => (
          <>
            {!softVerifiedUser ? (
              <Section>
                <Wrapper>
                  <SoftVerify handleSoftVerifiedUserSubmit={setNativeSoftVerifiedUser} />
                </Wrapper>
              </Section>
            ) : (
              <>
                <Step
                  step={step}
                  setStep={setStep}
                  products={products}
                  user={user}
                  signupError={signupError}
                  selectProduct={selectProduct}
                  selectedProductHandle={selectedProductHandle}
                  selectedVariantId={selectedVariantId}
                  submitOrder={submitOrder}
                />
                {inactiveCampaign ? (
                  <Modal visible>
                    <ModalSection className="u-text-center">
                      <H3>Sorry!</H3>
                      <p>The campaign is currently inactive.</p>
                    </ModalSection>
                  </Modal>
                ) : (
                  <SignUpModal
                    visible={signUpModalVisibility}
                    close={() => {
                      setSignupModalVisibility(false)
                    }}
                    signupError={signupError}
                    handleUserSignup={signup}
                    hideLogin
                    utmCampaign={utmCampaign}
                    utmSource={utmSource}
                    utmMedium={utmMedium}
                  />
                )}
              </>
            )}
          </>
        )}
      </NativeAuthConsumer>
    </Layout>
  )
}

BoostPlan.propTypes = {
  history: object.isRequired,
  createBoostPlanOrder: func.isRequired
}

export default withRouter(BoostPlan)
