import React from 'react'
import { func, object, bool, string } from 'prop-types'
import { withFormik, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import { formatPhone } from 'utils/formatters'

import * as spacing from 'styles/spacing'
import Fieldset from 'components/Fieldset/index'
import FieldsetItem from 'components/Fieldset/FieldsetItem'
import ProductRow from 'components/ProductRow'
import AddressComplete from 'components/AddressComplete'
import Tooltip from 'components/Tooltip'
import Input from 'components/Input'
import { Muted } from 'styles/typography'
import Button from 'components/button/Button'

const TooltipText = styled.p`
  margin: 0 ${spacing.tiny};
  cursor: pointer;
`

const OrderForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setValues,
  setFieldValue,
  setErrors,
  isSubmitting,
  selectedProduct,
  selectedVariantId,
  user,
  submitOrder
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Fieldset>
        <FieldsetItem xs="1-1">
          <ProductRow product={selectedProduct} selectedVariantId={selectedVariantId} />
        </FieldsetItem>
        <>
          <FieldsetItem xs="1-1" md="3-4">
            <AddressComplete
              type="text"
              label={
                <>
                  <span>Shipping address</span>
                  <Tooltip
                    content={
                      'Your shipping address must match your province of residence, we will only use this information for delivery'
                    }
                  >
                    <TooltipText>(?)</TooltipText>
                  </Tooltip>
                </>
              }
              value={values.address}
              placeholder="Start typing..."
              name="address"
              onChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              meta={{
                error: errors.address,
                touched: true
              }}
              setErrors={setErrors}
              userProvince={user.province}
            />
          </FieldsetItem>
          <FieldsetItem xs="1-2" md="1-4">
            <Input
              label="Address 2"
              name="subBuilding"
              value={values.subBuilding}
              onChange={handleChange}
              placeholder="Apt, suite, floor"
              meta={{
                error: errors.subBuilding,
                touched: touched.subBuilding
              }}
              onBlur={handleBlur}
            />
          </FieldsetItem>
        </>
        <FieldsetItem xs="1-2">
          <Field
            component={Input}
            label={
              <>
                <span>Phone number</span>
                <Tooltip
                  content={
                    'Your phone number is required for shipping purposes and we will only use this information for delivery'
                  }
                >
                  <TooltipText>(?)</TooltipText>
                </Tooltip>
              </>
            }
            name="phone"
            id="phone"
            placeholder="123-456-7890"
            type="text"
            value={formatPhone(values.phone)}
            normalize={formatPhone}
            onChange={handleChange}
            meta={{
              error: errors.phone,
              touched: touched.phone
            }}
            onBlur={handleBlur}
          />
        </FieldsetItem>
        <FieldsetItem xs="1-1">
          <p>
            <Muted as="small">
              Please note that we currently only ship within Canada to your province of residence, excluding the
              provinces of Quebec (QC), Nova Scotia (NS) and the territories: Northwest Territories (NT), Nunavut (NU),
              and Yukon (YT). Applicable taxes and delivery fee will be confirmed at the checkout. All parcels will
              require a signature from the courier. Please ensure that you have a valid government-issued photo ID to
              present upon delivery, to confirm that you are an adult who has reached the legal age to purchase vaping
              products.
            </Muted>
          </p>
        </FieldsetItem>
        <FieldsetItem xs="1-1">
          <Button
            theme="primary"
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            data-gtm-action="submit"
          >
            Submit
          </Button>
        </FieldsetItem>
      </Fieldset>
    </form>
  )
}

OrderForm.propTypes = {
  // step: object,
  user: object,
  selectedProduct: object,
  // setStep: func,
  values: object,
  errors: object,
  touched: object,
  handleChange: func,
  handleBlur: func,
  handleSubmit: func,
  selectedVariantId: string,
  setValues: func,
  setFieldValue: func,
  setErrors: func,
  submitOrder: func,
  isSubmitting: bool
}

const mapPropsToValues = ({ user }) => ({
  addressLabel: user?.addressLabel || '',
  address: user?.address || '',
  province: user?.province || '',
  city: user?.city || '',
  country: user?.country || '',
  postalCode: user?.postalCode || '',
  subBuilding: user?.subBuilding || '',
  phone: user?.phoneNumber || '',
  geocodedAddress: true
})

const validationSchema = props => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      address: Yup.string().required('Address is required')
    })
  })
}

const handleSubmit = async (values, { props, setFieldError, setSubmitting, setFieldValue }) => {
  setSubmitting(true)

  if (props.user.province !== values.province) {
    setFieldError('address', 'Shipping province does not match your province of residence')
  } else {
    const order = {
      shipping_address: {
        address1: values.address,
        address2: values.subBuilding,
        city: values.city,
        country: values.country,
        phone: props.user.phoneNumber,
        last_name: props.user.lastName,
        first_name: props.user.firstName,
        province_code: values.province,
        zip: values.postalCode,
        geocodedAddress: values.geocodedAddress
      }
    }

    await props.submitOrder(order, props.user)
  }

  setSubmitting(false)
}

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  validateOnChange: false
})(OrderForm)
