// Primary

export const blue = '#48A2E5'
export const slate = '#006FD6'

// Greys

export const greyDark = '#1E2533'
export const grey = '#414857'
export const greyLight = '#8B93A3'
export const greyLightest = '#e2e6eb'
export const offWhite = '#F5F7FA'

// Misc

export const white = '#fff'
export const black = '#000'
export const red = '#D72D2D'
export const green = '#7EB68C'
export const yellow = '#FFD00B'

// Set colors

export const primaryColor = blue
export const secondaryColor = slate
export const backgroundColor = offWhite
export const borderColor = greyLightest
