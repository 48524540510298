export const formatPhone = phone => {
  const onlyNumbers = phone.replace(/[^0-9]/g, '')
  const last4 = onlyNumbers.slice(-4)
  const first3 = onlyNumbers.slice(-7, -4)
  const areaCode = onlyNumbers.slice(-10, -7)
  const prefix = onlyNumbers.slice(-13, -10)
  const countryCode = prefix ? `+${prefix}` : ''

  return [countryCode, areaCode, first3, last4].filter(Boolean).join('-')
}
