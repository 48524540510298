import React from 'react'
import styled, { css } from 'styled-components'
import { string, bool, node, func } from 'prop-types'
import { stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { white, borderColor, primaryColor, greyLight } from 'styles/colors'
import { animationTime, animationCurve, borderRadius, square } from 'styles/global'
import { fontSizes } from 'styles/typography'
import Icon from 'components/Icon'

const Container = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  & + & {
    margin-top: ${spacing.medium};
  }
`

const Control = styled.span`
  margin-right: ${stripUnit(spacing.small) * 1.5 + 'px'};
  flex-shrink: 0;

  ${props =>
    props.large &&
    css`
      margin-right: ${spacing.medium};
    `}
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const Indicator = styled.div`
  ${square`22px`};
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: ${stripUnit(borderRadius) * 0.5 + 'px'};
  flex-shrink: 0;
  background-color: ${white};
  border: 1px solid ${borderColor};
  transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve};

  ${props =>
    props.large &&
    css`
      border-radius: ${borderRadius};
      ${square`32px`};
    `}

  ${Input}:checked + & {
    background-color: ${primaryColor};
    border-color: ${primaryColor};
  }
`

const Check = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0.6);
  transition: opacity ${animationTime} ${animationCurve}, transform ${animationTime} ${animationCurve};

  ${Input}:checked ~ ${Indicator} & {
    opacity: 1; /* Show icon when checkbox is checked */
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
`

const Label = styled.div`
  text-align: left;
  margin-top: -2px; /* Align text with checkbox */
`

const Hint = styled.small`
  color: ${greyLight};
  font-size: ${fontSizes.tiny};
`

const Checkbox = ({ label, hint, checked, onChange, name, large }) => (
  <Container>
    <Control large={large}>
      <Input
        checked={checked}
        onChange={onChange}
        name={name} />
      <Indicator large={large}>
        <Check
          icon="checkmark-small"
          white
          medium={large} />
      </Indicator>
    </Control>
    <Label>{label}</Label>
    {hint && <Hint>{hint}</Hint>}
  </Container>
)

Checkbox.propTypes = {
  label: node.isRequired,
  hint: string,
  checked: bool,
  large: bool,
  onChange: func,
  name: string
}

export default Checkbox
