import React from 'react'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Button from 'components/button/Button'
import { Heading, Subheading } from './Final'

export default () => (
  <Section className="u-text-center">
    <Wrapper>
      <img src={require(`static/images/icons/error.png`)} width="66" />
      <Heading>Sorry, You already have an account</Heading>
      <Subheading>This offer is only available for new Vype members</Subheading>
      <Button theme="primary" href="https://govype.ca">
        Return to govype.ca
      </Button>
    </Wrapper>
  </Section>
)
