import React, { useState } from 'react'
import { object, func } from 'prop-types'
import styled from 'styled-components'
import { withFormik } from 'formik'

import { CANADIAN_PROVINCES } from 'utils/constants'

import { fontWeights } from 'styles/typography'
import { white, grey } from 'styles/colors'
import { media } from 'styles/media'
import { borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Fieldset from 'components/Fieldset/index'
import FieldsetItem from 'components/Fieldset/FieldsetItem'
import Wrapper from 'components/Wrapper'
import Dropdown from 'components/Dropdown'
import FormError from 'components/FormError'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
  border-radius: ${borderRadius};
  background-color: ${white};
  padding: ${spacing.large};

  ${media.medium`
    padding: ${spacing.xLarge};
  `};

  ${media.large`
    padding: ${spacing.xxLarge};
    max-width: 500px;
  `};
`

const Heading = styled.p`
  font-weight: ${fontWeights.medium};
  margin-bottom: ${spacing.xLarge};

  ${media.medium`
    margin-bottom: ${spacing.xxLarge};
  `};
`

const SoftVerify = ({ values, handleChange, handleSubmit, handleSoftVerifiedUserSubmit }) => {
  const [underaged, setUnderaged] = useState(false)

  return (
    <Container>
      <Wrapper>
        <Inner>
          <Heading>
            To gain access to our platform, please choose your province of residence and confirm you are at least 19
            years of age.
          </Heading>
          <form onSubmit={handleSubmit}>
            <Fieldset>
              <FieldsetItem xs="1-1">
                <Dropdown
                  label="Province"
                  name="province"
                  full
                  value={values.province}
                  options={CANADIAN_PROVINCES}
                  onChange={handleChange}
                />
              </FieldsetItem>
              {underaged && (
                <FieldsetItem xs="1-1">
                  <FormError>You must be at least 19 years of age</FormError>
                </FieldsetItem>
              )}
              <FieldsetItem xs="1-1">
                <ButtonGroup>
                  <Button theme="primary" type="submit">
                    I am 19 or over
                  </Button>
                  <Button
                    theme="custom"
                    variant="outline"
                    customColor={grey}
                    type="button"
                    onClick={e => {
                      e.preventDefault()
                      setUnderaged(true)
                    }}
                  >
                    I am not 19+
                  </Button>
                </ButtonGroup>
              </FieldsetItem>
            </Fieldset>
          </form>
        </Inner>
      </Wrapper>
    </Container>
  )
}

SoftVerify.propTypes = {
  values: object.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  handleSoftVerifiedUserSubmit: func.isRequired
}

const handleSubmit = (values, { props, setSubmitting }) => {
  setSubmitting(true)
  props.handleSoftVerifiedUserSubmit({ ...values })
  setSubmitting(false)
}

const mapPropsToValues = () => ({
  province: 'AB'
})

export default withFormik({
  mapPropsToValues,
  handleSubmit,
  validateOnChange: false
})(SoftVerify)
