import React from 'react'
import Helmet from 'react-helmet'

export default () => (
  <Helmet
    title="Vape - shop online - Vype Canada"
    meta={[
      {
        name: 'description',
        content:
          'Vape shop online, in Canada. Vype offers you the very best vaporizers to kick start your vaping experience and competitive prices. Take a look here today.'
      }
    ]}
  />
)
