import gql from 'graphql-tag'

export const getProducts = gql`
  {
    products(first: 25, query: "title:'Vype ePen 3' OR 'Vype ePod'") {
      edges {
        node {
          title
          handle
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          variants(first: 10) {
            edges {
              node {
                title
                id
                sku
                image {
                  originalSrc
                }
              }
            }
          }
          images(first: 10) {
            edges {
              node {
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`

export const getEpodCartridges = gql`
  {
    collectionByHandle(handle: "vype-epod-vpro-cartridges") {
      title
      products(first: 25) {
        edges {
          node {
            title
            images(first: 1) {
              edges {
                node {
                  transformedSrc
                }
              }
            }
          }
        }
      }
    }
  }
`
