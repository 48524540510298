import React from 'react'
import ReactDOM from 'react-dom'

import { client } from 'lib/graphql/client'

import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { NativeAuthProvider } from 'contexts/NativeAuth'

import App from './App.js'

const Skeleton = () => (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <NativeAuthProvider>
        <App />
      </NativeAuthProvider>
    </ApolloProvider>
  </BrowserRouter>
)

ReactDOM.render(<Skeleton />, document.getElementById('root'))
