import React from 'react'
import styled from 'styled-components'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import { H1, H3, fontWeights, lineHeights } from 'styles/typography'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import Device from './Device'

export const Heading = styled(H1)`
  margin: ${spacing.large} 0 ${spacing.medium};

  ${media.small`
    margin: ${spacing.large} 0;
  `};

  ${media.medium`
    margin: ${spacing.xLarge} 0;
  `};

  ${media.large`
    margin: ${spacing.xxLarge} 0;
  `};
`

export const Subheading = styled(H3)`
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.base};
  margin-bottom: ${spacing.large};

  ${media.medium`
    margin-bottom: ${spacing.xLarge};
  `};

  ${media.large`
    margin-bottom: ${spacing.xxLarge};
  `};
`

export default () => (
  <>
    <Section className="u-text-center">
      <Wrapper>
        <img src={require(`static/images/icons/checkmark.png`)} width="66" />
        <Heading>Welcome aboard</Heading>
        <Subheading>
          You have successfully registered to govype.ca. Validate your account on the email address provided and in
          exchange, we will send you a vaping product in the mail.
        </Subheading>
      </Wrapper>
    </Section>
    <Device />
  </>
)
