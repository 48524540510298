import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { node, bool, string } from 'prop-types'
import { mix, readableColor } from 'polished'

import { blue, red, white, primaryColor, black } from 'styles/colors'
import { animationTime, animationCurve, inputHeight, inputHeightMobile } from 'styles/global'
import { fontWeights, fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'
import { media } from 'styles/media'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${inputHeightMobile};
  border-radius: ${spacing.xLarge};
  padding: 0 ${spacing.large};
  cursor: pointer;
  text-align: center;
  transition: all ${animationTime} ${animationCurve};

  ${media.medium`
    height: ${inputHeight};
  `};

  ${media.large`
    padding: 0 ${spacing.xLarge};
  `};

  ${props =>
    props.fullwidth &&
    css`
      display: flex;
      width: 100%;
    `}

  ${props =>
    props.theme === 'primary' &&
    css`
      color: ${white};
      background-color: ${blue};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, blue, black)};
      }
    `}

  ${props =>
    props.theme === 'secondary' &&
    css`
      color: ${primaryColor};
      background-color: transparent;
      border: 2px solid ${primaryColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${primaryColor};
        color: ${white};
      }
    `}

  ${props =>
    props.theme === 'tertiary' &&
    css`
      color: ${white};
      background-color: transparent;
      border: 2px solid ${white};

      &:hover,
      &:focus,
      &:active {
        background-color: ${white};
        color: ${black};
      }
    `}

  ${props =>
    props.theme === 'negative' &&
    css`
      color: ${white};
      background-color: ${red};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, red, black)};
      }
    `}

  ${props =>
    props.theme === 'custom' &&
    css`
      color: ${readableColor(props.customColor)};
      background-color: ${props.customColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, props.customColor, black)};
      }
    `}

  ${props =>
    props.theme === 'custom' &&
    props.variant === 'outline' &&
    css`
      color: ${props.customColor};
      background-color: transparent;
      border: 2px solid ${props.customColor};

      &:hover,
      &:focus,
      &:active {
        background-color: ${props.customColor};
        color: ${readableColor(props.customColor)};
      }
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const Spinner = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  animation: ${rotate} 0.75s linear infinite;
`

const Label = styled.span`
  font-weight: ${fontWeights.bold};
  transition: opacity ${animationTime} ${animationCurve};
  font-size: ${fontSizes.small};

  ${props =>
    props.isLoading &&
    css`
      opacity: 0;
    `};
`

const getElement = props => {
  if (props.to || props.href) return 'a'
  return 'button'
}

const Button = ({ children, theme, customColor, variant, isLoading, fullwidth, type = 'button', ...rest }) => (
  <Container
    fullwidth={fullwidth}
    theme={theme}
    variant={variant}
    isLoading={isLoading}
    disabled={isLoading}
    customColor={customColor}
    as={getElement(rest)}
    {...rest}
  >
    {isLoading && <Spinner icon="spinner" />}
    <Label isLoading={isLoading}>{children}</Label>
  </Container>
)

Button.propTypes = {
  children: node.isRequired,
  theme: string,
  variant: string,
  customColor: string,
  fullwidth: bool,
  isLoading: bool,
  type: string
}

export default Button
