import React from 'react'
import styled, { keyframes } from 'styled-components'

import { square } from 'styles/global'
import { media } from 'styles/media'
import Icon from 'components/Icon'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerIcon = styled(Icon)`
  animation: ${rotate} 1s linear infinite;
  ${square`32px !important`};

  ${media.small`
    ${square`40px !important`};
  `};

  ${media.medium`
    ${square`48px !important`};
  `};
`

const Spinner = () => <SpinnerIcon icon="spinner" brand />

export default Spinner
