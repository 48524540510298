import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'isomorphic-unfetch'

const cache = new InMemoryCache()

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.SHOPIFY_API_URI,
    credentials: 'same-origin',
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_API_ACCESS_TOKEN
    },
    fetch: (uri, options) => {
      return fetch(uri, options)
    }
  }),
  cache
})
