import React from 'react'
import styled from 'styled-components'
import { object, string } from 'prop-types'
import { stripUnit, rgba } from 'polished'

import { pricify } from 'utils/pricify'
import { animationTime, animationCurve, borderRadius } from 'styles/global'
import { grey, greyDark, black, white } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.large};
  background-color: ${white};
  border-radius: ${stripUnit(borderRadius) * 3 + 'px'};
  box-shadow: 0 2px 28px 0 ${rgba(black, 0.04)};
`

const PhotoWrap = styled.div`
  flex-shrink: 0;
  margin-right: ${spacing.medium};
`

const Photo = styled.img`
  backface-visibility: hidden;
  transition: opacity ${animationTime} ${animationCurve};
  width: 72px;
  border-radius: ${borderRadius};
`

const Title = styled.strong`
  display: block;
  margin-bottom: ${spacing.tiny};
  color: ${greyDark};
`

const Price = styled.span`
  color: ${grey};
`

const ProductRow = ({ product, selectedVariantId }) => {
  let { title } = product
  const selectedVariant = product.variants.edges.find(variant => variant.node.id === selectedVariantId).node

  return (
    <Container>
      <PhotoWrap>
        <Photo src={selectedVariant.image.originalSrc} />
      </PhotoWrap>
      <div>
        {title && <Title>{title}</Title>}
        <Price>{pricify({ amount: 0 })}</Price>
      </div>
    </Container>
  )
}

ProductRow.propTypes = {
  product: object.isRequired,
  selectedVariantId: string.isRequired
}

export default ProductRow
