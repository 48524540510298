import React from 'react'

import { NativeAuthConsumer } from 'contexts/NativeAuth'
import Layout from 'layouts/Layout'
import EmailVerification from 'components/EmailVerification'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'

export default () => (
  <Layout>
    <Section className="u-text-center">
      <Wrapper>
        <NativeAuthConsumer>{({ verifyEmail }) => <EmailVerification verifyEmail={verifyEmail} />}</NativeAuthConsumer>
      </Wrapper>
    </Section>
  </Layout>
)
