export const ALLOWED_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'ON', 'PE', 'SK']

export const CANADIAN_PROVINCES = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  Saskatchewan: 'SK',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  'Newfoundland and Labrador': 'NL',
  Quebec: 'QC'
}

export const CANADIAN_LEGAL_AGES = {
  AB: 18,
  BC: 19,
  MB: 18,
  NB: 19,
  SK: 18,
  ON: 19,
  PE: 19,
  NL: 19
}

export const PRODUCT_HANDLES =
  process.env.TARGET_ENV !== 'production' ? ['vype-epod-vape', 'vype-epen-3'] : ['vype-epod-vape', 'vype-epen-3']

export const MOCKED_USER = {
  agentSource: 'Boost Plan',
  firstName: 'Ben',
  lastName: 'Ben',
  email: 'benoitpaquier001@gmail.com',
  phoneNumber: '4389283672',
  password: 'temptemp',
  dob: '1993/12/12',
  address: '205-3506 4th Ave W Vancouver V6R 1N8 BC Canada',
  emailSubscription: true,
  consent: true,
  province: 'BC',
  city: 'Vancouver',
  country: 'CANADA',
  postalCode: 'V6R 1N8'
}
