import React, { useEffect, useState } from 'react'
import { object, func } from 'prop-types'
import { withRouter } from 'react-router-dom'
import querystring from 'query-string'

import Loading from 'components/Loading'
import { Heading, Subheading } from 'components/BoostPlan/Final'
import Button from 'components/button/Button'

const EmailVerification = ({ history, verifyEmail }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    checkEmail()
  }, [])

  const checkEmail = async () => {
    if (typeof verifyEmail !== 'undefined') {
      try {
        await verifyEmail({ token: querystring.parse(history.location.search).token })
      } catch (e) {
        setError('Oups, something went wrong')
      }

      setLoading(false)
    }
  }

  if (loading) return <Loading />

  if (error?.length > 0)
    return (
      <>
        <img src={require(`static/images/icons/error.png`)} width="66" />
        <Heading>Oops!</Heading>
        <Subheading>Something went wrong</Subheading>
      </>
    )

  return (
    <>
      <img src={require(`static/images/icons/checkmark.png`)} width="66" />
      <Heading>Success!</Heading>
      <Subheading>Your email address has been verified and order will be shipped soon.</Subheading>
      <Button href="https://govype.ca" theme="primary">
        Go to govype.ca
      </Button>
    </>
  )
}

EmailVerification.propTypes = {
  history: object.isRequired,
  verifyEmail: func.isRequired
}

export default withRouter(EmailVerification)
