import React from 'react'
import { node, bool } from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { white, black } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { H3 } from 'styles/typography'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Button from 'components/button/Button'

const Container = styled.div`
  position: relative;
  padding: ${spacing.large};
  background-color: ${white};
  border-radius: ${borderRadius};
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.large};
  box-shadow: 0 2px 28px 0 ${rgba(black, 0.04)};

  ${media.medium`
    margin-top: ${spacing.xLarge};
  `};

  ${media.large`
    max-width: 750px;
    margin-top: ${spacing.xxLarge};
    padding: ${spacing.large} ${spacing.xLarge};
  `};

  ${media.xLarge`
    margin-top: ${spacing.xxxLarge};
  `};
`

const Heading = styled(H3)`
  display: block;
  margin-bottom: ${spacing.tiny};
`

const Figure = styled.img`
  position: absolute;
  top: -${spacing.large};
  left: ${spacing.large};
  width: 28px;

  ${media.small`
    top: auto;
    bottom: ${spacing.medium};
  `};

  ${media.medium`
    left: ${spacing.xxLarge};
  `};

  ${media.large`
    left: ${spacing.xxxLarge};
  `};
`

const Inner = styled.div`
  padding-left: 52px;

  ${media.small`
    display: flex;
    align-items: center;
  `};

  ${media.medium`
    padding-left: 80px;
  `};

  ${media.large`
    padding-left: 88px;
  `};
`

const Main = styled.div`
  flex: 1;
  margin-bottom: ${spacing.medium};

  ${media.small`
    margin-bottom: 0;
    padding-right: ${spacing.large};
  `};
`

const Device = ({ children, narrow }) => (
  <Section flushTop>
    <Wrapper>
      <Container>
        <Inner>
          <Figure src={require(`static/images/device.png`)} alt="Vype device" />
          <Main>
            <Heading>Your ePod is on the way</Heading>
            <p>Get more information on your profile</p>
          </Main>
          <Button href="https://govype.ca/account" theme="primary">
            Go to my profile
          </Button>
        </Inner>
      </Container>
    </Wrapper>
  </Section>
)

Device.propTypes = {
  children: node.isRequired,
  narrow: bool
}

export default Device
