import React, { useState, useEffect } from 'react'
import { node, object } from 'prop-types'
import { withRouter } from 'react-router-dom'
import omit from 'lodash/omit'

import Loading from 'components/Loading'
// import { MOCKED_USER } from 'utils/constants'

const NativeAuthContext = React.createContext()

let NativeAuth = null

const NativeAuthProvider = ({ children, history }) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [province, setProvince] = useState(null)
  const [token, setToken] = useState(null)
  const [signupError, setSignupError] = useState('')
  const [signUpModalVisibility, setSignupModalVisibility] = useState(false)
  const [softVerifiedUser, setSoftVerifyUser] = useState(null)

  useEffect(() => {
    const init = async () => {
      NativeAuth = await import('../lib/auth-sdk')

      NativeAuth.init({
        storeName: 'vype',
        name: 'vype',
        website: 'govype.ca',
        phone: '1-844-746-8973',
        email: 'info@govype.ca',
        authApiUrl: process.env.AUTH_API,
        frontend: 'https://auth-frontend-v2.netlify.com'
      })

      NativeAuth.iframe.addEventListener('load', initialAuthCheck)
    }

    init()
  }, [])

  const initialAuthCheck = async () => {
    const { user } = await NativeAuth.getUserState()
    const { softVerifiedUser } = await NativeAuth.getSoftVerifiedUser()
    const allowedRoute = history.location.pathname !== '/email-verification'

    setSoftVerifyUser(softVerifiedUser)
    if (softVerifiedUser) setProvince(softVerifiedUser.user.province)
    if (history.location.pathname === '/not-eligible' && !user) history.push('/')
    if (user && allowedRoute) history.push('/not-eligible')

    setLoading(false)
  }

  const signup = async user => {
    const response = await NativeAuth.screenEmail(user.email)

    if (response.error === 'Email account invalid') {
      setSignupError('emailAccountInvalid')
      return
    }

    try {
      const response = await NativeAuth.signup({
        user: omit(user, ['passwordConfirm', 'addressLabel', 'street']),
        storeName: 'vype'
      })

      if (response.method === 'idVerification') {
        setSignupError('idVerification')
      } else {
        setUser(response.user)
        setToken(response.token)
        setProvince(response.user.province)
        setSignupError('')
        setSignupModalVisibility(false)
      }
    } catch (e) {
      if (e.data === 'emailAlreadyExists' || e.data.message === 'emailAlreadyExists') {
        setSignupError('emailAlreadyExists')
        return 'emailAlreadyExists'
      } else if (e.data === 'accountInvalid' || e.data.message === 'accountInvalid') {
        setSignupError('accountInvalid')
        return 'accountInvalid'
      } else {
        return e.data
      }
    }
  }

  const setNativeSoftVerifiedUser = async user => {
    try {
      await NativeAuth.setSoftVerifyUser({ user })

      if (user.province === 'QC') {
        history.push('https://govype.ca/qc')
      } else {
        setProvince(user.province)
        setSoftVerifyUser(user)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const createBoostPlanOrder = async order => {
    await NativeAuth.createBoostPlanOrder(order, token)
  }

  const value = {
    user,
    signup,
    province,
    token,
    signupError,
    signUpModalVisibility,
    setSignupModalVisibility,
    setNativeSoftVerifiedUser,
    createBoostPlanOrder,
    verifyEmail: NativeAuth?.verifyBoostPlanEmail,
    softVerifiedUser
  }

  if (loading) return <Loading />
  return <NativeAuthContext.Provider value={value}>{children}</NativeAuthContext.Provider>
}

NativeAuthProvider.propTypes = {
  children: node.isRequired,
  history: object.isRequired
}

const { Consumer } = NativeAuthContext
const WithRouterNativeAuthProvider = withRouter(NativeAuthProvider)
export { Consumer as NativeAuthConsumer, WithRouterNativeAuthProvider as NativeAuthProvider }

export default NativeAuthContext
