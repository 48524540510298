import styled from 'styled-components'

import { greyDark } from 'styles/colors'
import { fontSizes, fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

export default styled.label`
  display: block;
  margin-bottom: ${spacing.small};
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.medium};
  text-align: left;
  color: ${greyDark};
`
