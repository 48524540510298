import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { NativeAuthConsumer } from 'contexts/NativeAuth'
import BoostPlan from 'pages'
import NotEligible from 'pages/not-eligible'
import EmailVerification from 'pages/email-verification'

const BoostPlanWithUser = () => (
  <NativeAuthConsumer>
    {({ createBoostPlanOrder }) => <BoostPlan createBoostPlanOrder={createBoostPlanOrder} />}
  </NativeAuthConsumer>
)

export default () => {
  return (
    <Switch>
      <Route exact path="/" component={BoostPlanWithUser} />
      <Route path="/not-eligible" component={NotEligible} />
      <Route path="/email-verification" component={EmailVerification} />
    </Switch>
  )
}
