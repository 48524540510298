import React, { useState } from 'react'
import styled from 'styled-components'
import { object, func } from 'prop-types'
import { rgba, stripUnit } from 'polished'

import { pricify } from 'utils/pricify'
import { H3, H4, fontWeights } from 'styles/typography'
import { primaryColor, white, black } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { borderRadius } from 'styles/global'
import Button from 'components/button/Button'
import Fieldset from 'components/Fieldset/index'
import FieldsetItem from 'components/Fieldset/FieldsetItem'
import Dropdown from 'components/Dropdown'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: ${white};
  border-radius: ${stripUnit(borderRadius) * 3 + 'px'};
  padding: ${spacing.xLarge};
  box-shadow: 0 2px 28px 0 ${rgba(black, 0.04)};

  ${media.large`
    padding: ${spacing.xxLarge} ${spacing.xLarge};
  `};
`

const PhotoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  margin-bottom: ${spacing.medium};

  ${media.large`
    margin-bottom: ${spacing.large};
  `};
`

const Photo = styled.img`
  backface-visibility: hidden;
`

const Title = styled(H3)`
  margin-bottom: ${spacing.small};
`

const Price = styled(H4)`
  color: ${primaryColor};
  font-weight: ${fontWeights.regular};
  margin-bottom: ${spacing.large};
`

const Footer = styled.div`
  width: 100%;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
`

const ProductItem = ({ product, selectProduct }) => {
  let { title } = product
  const variantsTitles = product.variants.edges.map(variant => variant.node.title)
  const [selectedVariant, setSelectedVariant] = useState(product.variants.edges[0].node)

  let variantsOptions = {}

  variantsTitles.forEach(title => {
    variantsOptions = { ...variantsOptions, [title]: title }
  })

  const handleChange = e => {
    const title = variantsOptions[e.target.value]
    const variant = product.variants.edges.find(variant => variant.node.title === title).node
    setSelectedVariant(variant)
  }

  return (
    <Container>
      <PhotoWrap>
        <Photo src={selectedVariant.image.originalSrc} />
      </PhotoWrap>
      {title && <Title>{title}</Title>}
      <Price>{pricify({ amount: 0 })}</Price>
      <Footer>
        <Fieldset>
          {variantsTitles.length > 1 && (
            <FieldsetItem xs="1-1">
              <Dropdown
                label="Color"
                name="variant"
                value={selectedVariant.title}
                options={variantsOptions}
                onChange={handleChange}
                full
              />
            </FieldsetItem>
          )}
          <FieldsetItem xs="1-1">
            <Button
              theme="primary"
              fullwidth
              onClick={() => {
                selectProduct(product.handle, selectedVariant.id)
              }}
            >
              Select
            </Button>
          </FieldsetItem>
        </Fieldset>
      </Footer>
    </Container>
  )
}

ProductItem.propTypes = {
  product: object.isRequired,
  selectProduct: func.isRequired
}

export default ProductItem
