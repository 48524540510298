import moment from 'moment'
import { CANADIAN_LEGAL_AGES } from 'utils/constants'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmail = value => {
  return (!value || emailRegex.test(value))
}

export const minLength = min => value => {
  if (!value || value.length >= min) return true
  return false
}

export const match = (value, { password }) => {
  if (!value || password === value) return
  return 'Passwords must match.'
}

const dateRegex = /^\d{8}$/
const addSlashesToDate = value => value.slice(0, 4) + '/' + value.slice(4, 6) + '/' + value.slice(6, 8)

export const dobIsValid = () => value => {
  if (!value) return false
  const normalizeAge = value.replace(/\//g, '')
  if (!dateRegex.test(normalizeAge)) return false
  const dateWithSlashes = addSlashesToDate(normalizeAge + '')
  const dob = new Date(dateWithSlashes)
  return dob instanceof Date && isFinite(dob)
}

export const isAddressIncomplete = values => value => {
  const { province, city, country, postalCode } = values

  return (
    province !== undefined ||
    city !== undefined ||
    country !== undefined ||
    postalCode !== undefined
  )
}

export const dobIsUnderaged = province => value => {
  if (!value) return true
  if (!province) return true
  const legalAge = CANADIAN_LEGAL_AGES[province]
  const dob = moment(value.split('/').join('-'))
  const age = moment().diff(dob, 'years')
  return age >= legalAge
}
