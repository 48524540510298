import React from 'react'
import styled from 'styled-components'
import { rgba, stripUnit } from 'polished'
import { node, string, oneOfType } from 'prop-types'

import { white, greyDark } from 'styles/colors'
import { animationTime, animationCurve, borderRadius } from 'styles/global'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 260px;
  margin-left: -130px;
  text-align: center;
  left: 50%;
  bottom: calc(100% + ${stripUnit(spacing.small) * 1.5 + 'px'});
  background-color: ${rgba(greyDark, 0.93)};
  color: ${white};
  padding: ${spacing.medium};
  opacity: 0;
  font-weight: ${fontWeights.regular};
  visibility: hidden;
  transform: translateY(5px);
  backface-visibility: hidden;
  border-radius: ${borderRadius};
  transition: opacity ${animationTime} ${animationCurve}, transform ${animationTime} ${animationCurve},
    visibility ${animationTime} ${animationCurve};

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid ${rgba(greyDark, 0.93)};
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    backface-visibility: hidden;
  }

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
`

const Tooltip = ({ content, children }) => (
  <Container>
    <TooltipContent>{content}</TooltipContent>
    {children}
  </Container>
)

Tooltip.propTypes = {
  content: oneOfType([string, node]),
  children: node
}

export default Tooltip
