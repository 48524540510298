import { css, createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

import Normalize from './normalize'
import Width from './width'
import Utilities from './utilities'
import Slick from './slick'
import Typography from './typography'
import CanadaPost from './canadaPost'
import { offWhite, black } from './colors'

// Animation
export const animationTime = '0.3s'
export const animationCurve = 'ease-in-out'

// Border Radius
export const borderRadius = '5px'

// Inputs
export const inputHeight = '46px'
export const inputHeightMobile = '40px'
export const inputHeightSmall = '36px'

// Nav
export const navHeightMobile = '66px'
export const navHeight = '82px'

// Vertical padding
export const verticalPadding = padding => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`

// Square
export const square = size => css`
  width: ${size};
  height: ${size};
`

export const boxShadow = `box-shadow: 0 2px 4px 0 ${rgba(black, 0.05)};`

// Cover
export function cover(position) {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

// Styles

export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Width};
  ${Utilities};
  ${Typography};
  ${Slick};
  ${CanadaPost};

  body {
    background-color: ${offWhite};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.is-clipped,
    &.has-modal {
      overflow: hidden;
      position: fixed;
    }
  }
`
