import React from 'react'
import { func, object, bool, string } from 'prop-types'
import styled from 'styled-components'
import { withFormik, Field } from 'formik'
import * as Yup from 'yup'

import { minLength, dobIsValid, isAddressIncomplete, dobIsUnderaged } from 'utils/validators'
import { formatPhone } from 'utils/formatters'
import { CANADIAN_LEGAL_AGES } from 'utils/constants'

import Modal, { ModalSection } from 'components/Modal'
import Input from 'components/Input'
import NumberInput from 'components/NumberInput'
import AddressComplete from 'components/AddressComplete'
import Button from 'components/button/Button'
import Tooltip from 'components/Tooltip'
import Checkbox from 'components/Checkbox'
import { H4 } from 'styles/typography'
import { primaryColor } from 'styles/colors'
import Fieldset from 'components/Fieldset/index'
import FieldsetItem from 'components/Fieldset/FieldsetItem'
import FormError from 'components/FormError'

const Action = styled.button`
  color: ${primaryColor};
  border-bottom: 1px solid ${primaryColor};
`

const SignUpModal = ({
  visible,
  close = () => {},
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  setErrors,
  handleUserSignup,
  signupError,
  setLoginModalVisibility,
  hideLogin,
  utmCampaign,
  utmSource,
  utmMedium
}) => {
  const goToLogin = () => {
    close()
    setLoginModalVisibility(true)
  }

  return (
    <Modal visible={visible} close={close}>
      <ModalSection>
        {!hideLogin && (
          <p>
            Already have an account? <Action onClick={goToLogin}>Log in</Action>.
          </p>
        )}
        <H4>Create your Vype account</H4>
        <p>Receive exclusive offers. Track orders easily. Manage subscriptions.</p>
      </ModalSection>
      <ModalSection>
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <FieldsetItem xs="1-1" sm="1-2">
              <Input
                label="First name"
                placeholder="John"
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                meta={{
                  error: errors.firstName,
                  touched: touched.firstName
                }}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1" sm="1-2">
              <Input
                label="Last name"
                placeholder="Doe"
                name="lastName"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                meta={{
                  error: errors.lastName,
                  touched: touched.lastName
                }}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1" sm="1-2">
              <Input
                label="Email address"
                name="email"
                placeholder="john.doe@mail.com"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                meta={{
                  error: errors.email,
                  touched: touched.email
                }}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1" sm="1-2">
              <NumberInput
                label="Date of birth"
                name="dob"
                value={values.dob}
                contrast
                onChange={handleChange}
                numberFormat="####/##/##"
                placeholder="YYYY/MM/DD"
                meta={{
                  error: errors.dob,
                  touched: touched.dob
                }}
                onBlur={handleBlur}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1">
              <AddressComplete
                type="text"
                label="Address"
                value={values.address}
                placeholder="Start typing..."
                name="address"
                onChange={handleChange}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                values={values}
                meta={{
                  error: errors.address,
                  touched: errors.address
                }}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1" sm="1-2">
              <Field
                component={Input}
                label="Phone number"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="123-456-7890"
                type="text"
                value={formatPhone(values.phoneNumber)}
                normalize={formatPhone}
                onChange={handleChange}
                meta={{
                  error: errors.phoneNumber,
                  touched: touched.phoneNumber
                }}
                onBlur={handleBlur}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1" sm="1-2">
              <Input
                label="Password"
                name="password"
                placeholder="••••••••••"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                meta={{
                  error: errors.password,
                  touched: touched.password
                }}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1" sm="1-2">
              <Input
                label="Confirm password"
                name="passwordConfirm"
                placeholder="••••••••••"
                type="password"
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                meta={{
                  error: errors.passwordConfirm,
                  touched: touched.passwordConfirm
                }}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1">
              <Checkbox
                label={
                  <p>
                    <span>
                      Yes, I want to receive information about <strong>vype</strong> products, services and research
                      opportunities that may be of interest. You can unsubscribe at any time.
                    </span>
                    <Tooltip
                      content={
                        <p>
                          Vype
                          <br />
                          Imperial Tobacco Canada, 3711 Saint-Antoine St W, Montreal, QC H4C 3P6 , email:{' '}
                          <a href="mailto:support@govype.ca">support@govype.ca</a>, tel:{' '}
                          <a href="tel:18447468973">1-844-746-8973</a>
                        </p>
                      }
                    >
                      Hover over here for our contact information. Click the checkbox to consent.
                    </Tooltip>
                    <span>This information will be used to create your account and verify your identity.</span>
                  </p>
                }
                name="emailSubscription"
                onChange={handleChange}
                checked={values.emailSubscription}
              />
            </FieldsetItem>

            <FieldsetItem xs="1-1">
              <Checkbox
                label={
                  <p>
                    By checking this box, I acknowledge that I read, understood and agree to the&nbsp;
                    <a href="http://govype.ca/pages/terms-conditions">terms and conditions</a>.
                  </p>
                }
                name="consent"
                onChange={handleChange}
                checked={values.consent}
              />
            </FieldsetItem>

            {signupError === 'idVerification' && (
              <FieldsetItem xs="1-1">
                <FormError>
                  Oops, looks like we couldn't validate your identity with the address you provided. Have you moved
                  recently? Try entering your previous residential address. Please contact our customer service by phone
                  at 1-866-750-5893 or email us at <a to="mailto:support@govype.ca">support@govype.ca</a> for further
                  assistance.
                </FormError>
              </FieldsetItem>
            )}
            {signupError === 'emailAlreadyExists' && (
              <FieldsetItem xs="1-1">
                <FormError>Email address already exists.</FormError>
              </FieldsetItem>
            )}
            {signupError === 'accountInvalid' && (
              <FieldsetItem xs="1-1">
                <FormError>This account can not be created. For support, please contact customer service.</FormError>
              </FieldsetItem>
            )}
            {signupError === 'emailAccountInvalid' && (
              <FieldsetItem xs="1-1">
                <FormError>Email account invalid. (from BriteVerify)</FormError>
              </FieldsetItem>
            )}

            <FieldsetItem xs="1-1">
              <Button theme="primary" type="submit" disabled={!values.consent || isSubmitting} isLoading={isSubmitting}>
                Register
              </Button>
            </FieldsetItem>
          </Fieldset>
        </form>
      </ModalSection>
    </Modal>
  )
}

SignUpModal.propTypes = {
  close: func,
  visible: bool,
  hideLogin: bool,
  handleSubmit: func.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  isSubmitting: bool.isRequired,
  handleUserSignup: func.isRequired,
  setFieldValue: func.isRequired,
  setErrors: func.isRequired,
  signupError: string,
  utmCampaign: string,
  utmSource: string,
  utmMedium: string,
  setLoginModalVisibility: func
}

const handleSubmit = async (values, { setSubmitting, props, resetForm }) => {
  setSubmitting(true)
  await props.handleUserSignup(values)
  setSubmitting(false)
}

const mapPropsToValues = props => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  passwordConfirm: '',
  dob: '',
  addressLabel: '',
  address: '',
  emailSubscription: false,
  consent: false,
  street: '',
  province: '',
  city: '',
  country: '',
  postalCode: '',
  agentSource: 'Boost Plan',
  utmCampaign: props.utmCampaign,
  utmSource: props.utmSource,
  utmMedium: props.utmMedium
  // firstName: 'Ben',
  // lastName: 'Ben',
  // email: 'benoitpaquier001@gmail.com',
  // phoneNumber: '4389283672',
  // password: 'temptemp',
  // passwordConfirm: 'temptemp',
  // dob: '1993/12/12',
  // addressLabel: '205-3506 4th Ave W Vancouver V6R 1N8 BC Canada',
  // address: '205-3506 4th Ave W Vancouver V6R 1N8 BC Canada',
  // emailSubscription: true,
  // consent: true,
  // street: '4th Ave W Vancouver',
  // province: 'BC',
  // city: 'Vancouver',
  // country: 'CANADA',
  // postalCode: 'V6R 1N8',
  // utmCampaign: props.utmCampaign,
  // utmSource: props.utmSource,
  // utmMedium: props.utmMedium
})

const validationSchema = props => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string()
        .email('Email address is invalid')
        .required('Email address is required'),
      dob: Yup.string()
        .required('Date of birth is required')
        .test('dob', 'Birth of date is not valid', dobIsValid())
        .test('dob', `Must be at least ${CANADIAN_LEGAL_AGES[values.province]}`, dobIsUnderaged(values.province)),
      address: Yup.string()
        .required('Address is required')
        .test('address', 'You must select an address in the suggested list', isAddressIncomplete(values)),
      password: Yup.string()
        .required('Password is required')
        .test('password', 'Must be at least 8 characters', minLength(8)),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password confirmation is required')
    })
  })
}

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  validateOnChange: false
})(SignUpModal)
