import React from 'react'
import styled, { css } from 'styled-components'
import { node, bool } from 'prop-types'

import * as spacing from 'styles/spacing'
import { stripUnit } from 'polished'
import { media } from 'styles/media'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${stripUnit(spacing.medium) * 0.75 + 'px'};
  margin-right: -${stripUnit(spacing.medium) * 0.75 + 'px'};
  margin-bottom: -${spacing.large};

  ${props =>
    props.middle &&
    css`
      align-items: center;
    `}

  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}

  ${media.medium`
    margin-left: -${spacing.medium};
    margin-right: -${spacing.medium};
    margin-bottom: -${spacing.xLarge};

    ${props =>
      props.reverse &&
      css`
        flex-direction: row-reverse;
      `}
  `};
`

const GridRow = ({ children, middle, reverse, center }) => (
  <Container middle={middle} center={center} reverse={reverse}>
    {children}
  </Container>
)

GridRow.propTypes = {
  children: node.isRequired,
  middle: bool,
  reverse: bool,
  center: bool
}

export default GridRow
