import React, { useEffect } from 'react'
import { node, bool, string, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import * as spacing from 'styles/spacing'
import { media, mediaDown } from 'styles/media'
import { black, white } from 'styles/colors'
import { animationTime, animationCurve, borderRadius } from 'styles/global'
import Icon from 'components/Icon'
import Portal from 'components/Portal'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
  background-color: ${rgba(black, 0.85)};
  opacity: ${props => (props.visible && 1) || 0};
  visibility: ${props => (props.visible && 'visible') || 'hidden'};
  pointer-events: ${props => (props.visible && 'auto') || 'none'};
  z-index: 900;
  -webkit-overflow-scrolling: ${props => (props.visible && 'touch') || 'auto'};
`

const Table = styled.div`
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
`

const Cell = styled.div`
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: ${spacing.large};

  ${media.medium`
    padding: ${spacing.xLarge};
  `};

  ${media.large`
    padding: ${spacing.xxxLarge};
  `};
`

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  background: ${white};
  border-radius: ${borderRadius};
  overflow: hidden;
  opacity: 0;
  transform: ${props => (props.visible && 'none') || 'translateY(15px)'};
  transition: transform ${animationTime} ${animationCurve}, opacity ${animationTime} ${animationCurve};
  z-index: 450;
  background-color: ${white};

  ${mediaDown.medium`
    padding-top: ${spacing.small};
  `};

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      transform: none;
    `};
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 450;
  padding: ${spacing.medium};

  ${media.medium`
    top: ${spacing.tiny};
    right: ${spacing.tiny};
  `};
`

const Section = styled.section`
  padding: ${spacing.large};

  & + & {
    padding-top: 0;

    ${media.small`
      margin-top: -${spacing.small};
    `};

    ${media.large`
      margin-top: -${spacing.medium};
    `};
  }

  ${media.small`
    padding: ${spacing.xLarge};
  `};

  ${media.large`
    padding: ${spacing.xxLarge};
  `};
`

const Modal = ({ children, icon, title, visible, close, ...rest }) => {
  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'visible'
    document.body.style.position = visible ? 'fixed' : 'static'
  }, [visible])

  return (
    <Portal selector={'modals'}>
      <Container visible={visible}>
        <Table>
          <Cell>
            <Inner visible={visible}>
              {close && (
                <Close onClick={close}>
                  <Icon icon="close-small" />
                </Close>
              )}
              {children}
            </Inner>
          </Cell>
        </Table>
      </Container>
    </Portal>
  )
}

Modal.propTypes = {
  children: node.isRequired,
  visible: bool,
  icon: string,
  title: string,
  close: func
}

export const ModalSection = ({ children, className }) => <Section className={className}>{children}</Section>

ModalSection.propTypes = {
  children: node.isRequired,
  className: string
}

export default Modal
