import React, { useEffect, useRef } from 'react'
import { object, func, string } from 'prop-types'
import { ALLOWED_PROVINCES } from 'utils/constants'

import Input from 'components/Input'

const AddressComplete = props => {
  const el = useRef(null)

  const initAddressComplete = () => {
    if (window !== 'undefined') {
      window.addressComplete.listen('load', function(control) {
        control.listen('populate', function(address) {
          const { FormattedLine1, City, PostalCode, Province, CountryName } = address
          const addressLabel = `${FormattedLine1} ${City} ${PostalCode} ${Province} ${CountryName}`

          props.setFieldValue('addressLabel', addressLabel)
          props.setFieldValue('address', addressLabel)
          props.setFieldValue('street', FormattedLine1)
          props.setFieldValue('city', City)
          props.setFieldValue('postalCode', PostalCode)
          props.setFieldValue('province', Province)
          props.setFieldValue('country', CountryName)
          props.setFieldValue('subBuilding', address.subBuilding)

          if (!ALLOWED_PROVINCES.includes(Province)) {
            props.setErrors({ address: `vype ecommerce is not supported in ${Province}.` })
          } else {
            props.setErrors({})
          }
        })
      })
      window.addressComplete.load()
    }
  }

  useEffect(() => {
    const script = document.createElement('script')

    script.src = `https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=${
      process.env.CANADAPOST_API_KEY
    }`
    script.async = true
    el.current.appendChild(script)

    script.onload = () => {
      initAddressComplete()
    }
  }, [])

  return (
    <div ref={el}>
      <Input {...props} />
    </div>
  )
}

AddressComplete.propTypes = {
  type: string.isRequired,
  label: string.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  setErrors: func.isRequired,
  setFieldValue: func.isRequired,
  values: object.isRequired
}

export default AddressComplete
