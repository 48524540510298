import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'

import Logo from 'static/images/logo.svg'
import * as spacing from 'styles/spacing'
import { navHeight, navHeightMobile } from 'styles/global'
import { black, white } from 'styles/colors'
import { media } from 'styles/media'

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.large};
  height: ${navHeightMobile};

  ${media.medium`
    height: ${navHeight};
    padding: 0 ${spacing.xLarge};
  `};
`

const StyledLogo = styled(Logo)`
  fill: ${props => (props.contrast ? white : black)};
  width: 100px;
  height: 30px;

  ${media.medium`
    width: 114px;
    height: 34px;
  `};
`

const Header = ({ contrast }) => (
  <Container>
    <StyledLogo contrast={contrast} />
  </Container>
)

Header.propTypes = {
  contrast: bool
}

export default Header
