import React from 'react'
import { array, func, object } from 'prop-types'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import { H2 } from 'styles/typography'
import Product from 'components/BoostPlan/Product'
import Grid from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'

const Order = ({ products, step, setStep, selectProduct }) => {
  return (
    <Section>
      <Wrapper>
        <SectionBlock className="u-text-center">
          <H2>Select your product</H2>
        </SectionBlock>
        <SectionBlock>
          <Grid center>
            {products.map(product => (
              <GridItem xs="1-1" sm="1-2" md="1-3" equalHeight>
                <Product key={product.node.title} product={product.node} selectProduct={selectProduct} />
              </GridItem>
            ))}
          </Grid>
        </SectionBlock>
      </Wrapper>
    </Section>
  )
}

Order.propTypes = {
  products: array,
  step: object,
  setStep: func,
  selectProduct: func
}

export default Order
