import React from 'react'

import Layout from 'layouts/Layout'
import Ineligible from 'components/BoostPlan/Ineligible'

export default () => (
  <Layout>
    <Ineligible />
  </Layout>
)
